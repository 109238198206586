import {useEffect, useRef, useState} from 'react'
import {List, Map} from 'immutable'
import {useQuery} from '@apollo/client'

import {cleanContactsGraphQLResponse, cleanGraphQLResponse} from './graphQLTools'
import {CONTACTS, SCORE} from '../graphql/queries/userData'
import {ITEM_RESPONSES} from '../graphql/queries/itemResponse'
import {DEPUTIES, SPECIFIC_ITEMS_DEPUTIES, DEPUTIZERS} from '../graphql/queries/deputy'

/**
 * Used to get the previous value of a state or prop in a functional component.
 *
 * Taken from the react docs (https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state) as the
 * approach for getting previous state or props in functional component, but slightly changed to initialize with the passed in
 * value, otherwise a check between previous and current will always be different on first render.
*/
export const usePrevious = value => {
  const ref = useRef(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export const useDeputyData = everplanId => {
  const formattedEverplanId = parseInt(everplanId, 10)

  const {previousData: prevDeputiesData, data: deputiesData} = useQuery(DEPUTIES, {
    variables: {everplanId: formattedEverplanId}
  })

  const {previousData: prevSpecificItemsDeputiesData, data: specificItemsDeputiesData} = useQuery(SPECIFIC_ITEMS_DEPUTIES)

  const [deputies, setDeputies] = useState(cleanGraphQLResponse(deputiesData, 'deputies'))
  const [specificItemsDeputies, setSpecificItemsDeputies] = useState(cleanGraphQLResponse(specificItemsDeputiesData, 'specificItemsDeputies'))

  useEffect(() => {
    // prevDeputiesData is undefined unless something has changed
    if (prevDeputiesData)
      setDeputies(cleanGraphQLResponse(deputiesData, 'deputies'))
  }, [prevDeputiesData, deputiesData])

  useEffect(() => {
    if (prevSpecificItemsDeputiesData)
      setSpecificItemsDeputies(cleanGraphQLResponse(specificItemsDeputiesData, 'specificItemsDeputies'))
  }, [prevSpecificItemsDeputiesData, specificItemsDeputiesData])


  return {
    deputies,
    specificItemsDeputies
  }
}

export const useEverplanData = everplanId => {
  const formattedEverplanId = parseInt(everplanId, 10)

  const {
    loading: loadingItemResponses,
    error: itemResponsesError,
    previousData: prevItemResponses,
    data: itemResponsesData,
    refetch: refetchItemResponses
  } = useQuery(ITEM_RESPONSES, {variables: {everplanId: formattedEverplanId}})

  const {
    loading: loadingNewContacts,
    error: newContactsError,
    previousData: prevNewContactsData,
    data: newContactsData,
    refetch: refetchNewContacts
  } = useQuery(CONTACTS, {variables: {everplanId: formattedEverplanId}})

  const [itemResponses, setItemResponses] = useState(cleanGraphQLResponse(itemResponsesData, 'itemResponses'))
  const [newContacts, setNewContacts] = useState(cleanContactsGraphQLResponse(newContactsData))

  useEffect(() => {
    // When getting another user's everplan for the first time or if there is an error the itemResponses need to be reset.
    // loadingItemResponses is true only when getting another everplan's item responses for the first time.
    if (itemResponsesError || loadingItemResponses)
      return setItemResponses(List())

    // itemResponses is only empty when getting another person's everplan for the first time
    if (prevItemResponses || itemResponses.isEmpty())
      return setItemResponses(cleanGraphQLResponse(itemResponsesData, 'itemResponses'))
  }, [prevItemResponses, itemResponsesData, loadingItemResponses])

  useEffect(() => {
    if (newContactsError || loadingNewContacts)
      return setNewContacts(List())

    if (prevNewContactsData || newContacts.isEmpty())
      return setNewContacts(cleanContactsGraphQLResponse(newContactsData))
  }, [prevNewContactsData, newContactsData, loadingNewContacts])

  return {
    loadingEverplanData: loadingItemResponses || loadingNewContacts,
    itemResponses,
    refetchItemResponses,
    newContacts,
    refetchNewContacts
  }
}

export const useDeputizerData = userConfig => {
  const {previousData: previousDeputizersData, data: deputizersData, loading, refetch} = useQuery(DEPUTIZERS, {variables: {userId: parseInt(userConfig.get('id'), 10)}})

  const [deputizers, setDeputizers] = useState(cleanGraphQLResponse(deputizersData, 'deputizers'))

  useEffect(() => {
    if (previousDeputizersData || deputizers.isEmpty())
      setDeputizers(cleanGraphQLResponse(deputizersData, 'deputizers'))
  }, [previousDeputizersData, deputizersData])

  return {
    loadingDeputizers: loading,
    deputizers,
    refetchDeputizers: refetch
  }
}

export const useScoreData = userId => {
  const {previousData, data, startPolling, stopPolling, refetch} = useQuery(SCORE, {variables: {userId: parseInt(userId, 10)}})

  const [score, setScore] = useState(cleanGraphQLResponse(data, 'score', Map()))

  useEffect(() => {
    if (previousData || score.isEmpty())
      setScore(cleanGraphQLResponse(data, 'score'))
  }, [previousData, data])

  return {
    refetch,
    startPolling,
    stopPolling,
    score
  }
}
